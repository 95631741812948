/* @flow */
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import UploadPDF from './uploadPDF.jsx';

import { UPDATE_RELEASE_WITH_PDF } from '../../../../graphql/mutations/release';
import { GET_PHOTO_WITH_RELEASE_LIST } from '../../../../graphql/queries/photo';

import { showNotificationAndDismiss } from '../../../../helpers/actionCreators/redux';

export default compose(
  connect(null, { showNotification: showNotificationAndDismiss }),
  graphql(UPDATE_RELEASE_WITH_PDF, {
    name: 'updateReleaseWithPDF',
    options: (ownProps) => ({
      refetchQueries: [
        {
          query: GET_PHOTO_WITH_RELEASE_LIST,
          variables: {
            photoId: ownProps.photo.id,
            isPhotographer: true,
          },
        },
      ],
    }),
  })
)(UploadPDF);
