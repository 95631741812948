/** @jsxRuntime classic */
/** @jsx jsx */
/* @flow */
/* eslint-env browser */
import { Fragment } from 'react';
import { jsx } from 'theme-ui'; // eslint-disable-line
import t from 'counterpart'; // eslint-disable-line
import { Button, Text, Box } from '@eyeem-ui/atoms';

const Model = (props: { count: number }) => {
  const bullets = ['first', 'second', 'third'];

  return (
    <Fragment>
      <Text color="grey50">
        {t(
          'pview.marketDetails.releaseManagement.askForRelease.askModal.subline.model.intro',
          { count: props.count }
        )}
      </Text>
      <Box pl={3} py={2}>
        <ul
          sx={{
            listStyleType: 'disc',
            color: 'grey50',
          }}>
          {bullets.map((bullet) => (
            <li key={bullet}>
              <Text color="grey50">
                {t(
                  `pview.marketDetails.releaseManagement.askForRelease.askModal.subline.model.${bullet}`
                )}
              </Text>
            </li>
          ))}
        </ul>
      </Box>
      <a
        target="_blank"
        rel="noreferrer"
        href={t(
          'pview.marketDetails.releaseManagement.askForRelease.askModal.subline.model.link.href'
        )}>
        <Button variant="subtle">
          {t(
            'pview.marketDetails.releaseManagement.askForRelease.askModal.subline.model.link.text'
          )}
        </Button>
      </a>
      <Box py={2}>
        <Text color="grey50">
          {t(
            'pview.marketDetails.releaseManagement.askForRelease.askModal.subline.model.instructions'
          )}
        </Text>
      </Box>
    </Fragment>
  );
};

export default Model;
