import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import { SINGLE_PHOTO_QUERY_ALIAS } from '../../../graphql/constants';
import { GET_PHOTO_WITH_RELEASE_LIST } from '../../../graphql/queries/photo';

import { ADD_RELEASE } from '../../../graphql/mutations/release';

import EditReleases from './editReleases.jsx';

export default compose(
  graphql(GET_PHOTO_WITH_RELEASE_LIST, {
    props: ({ data: { photo }, ownProps }) => ({ photo, ...ownProps }),
    options: (ownProps) => ({
      variables: {
        photoId: ownProps.photoId,
        isPhotographer: true,
      },
    }),
  }),
  graphql(ADD_RELEASE, {
    name: 'addRelease',
    options: (ownProps) => ({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_PHOTO_WITH_RELEASE_LIST,
          variables: {
            photoId: ownProps.photoId,
            isPhotographer: true,
          },
        },
        SINGLE_PHOTO_QUERY_ALIAS,
      ],
    }),
  })
)(EditReleases);
