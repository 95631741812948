/* @flow */
/* eslint-env browser */
import { Component } from 'react';
import t from 'counterpart';

import { withApollo } from '@apollo/client/react/hoc';
import { Box } from '@eyeem-ui/atoms';

import ReleaseInfo from '../releaseInfo.jsx';
import Form from './form.jsx';

import { track } from '../../../../helpers/tools';

type Props = {
  close: CloseModal,
  updateReleaseWithPDF: Function,
  release: EyeEmRelease | EyeEmMultiRelease,
  photo: EyeemAsset,
  position: number,
  toggleUploadPDFState: Function,
  toggleViewPhotosState: Function,
  isMultiRelease: boolean,
  showNotification: Function,
};

type State = {
  submitPending: boolean,
};

class UploadPDF extends Component<Props, State> {
  state = {
    submitPending: false,
  };

  onSubmit = (values: {}) => {
    this.setState({
      submitPending: true,
    });

    this.props
      .updateReleaseWithPDF({
        variables: {
          payload: {
            ...values,
            type: this.props.release.releaseType,
            isMultiRelease: this.props.release.isMultiRelease,
            id: this.props.release.multiReleaseId || this.props.release.id,
          },
        },
        context: { hasUpload: true },
      })
      .then(() => {
        this.props.close();
        track({
          eventType: 'click_inbound',
          eventAction: 'click_button',
          eventLabel: t('multipleReleases.releaseInfo.next.label'),
          eventName: `release_${this.props.release.releaseType.toLowerCase()}_submit_pdf_jpeg_success`,
          eventPosition: t(
            'pview.marketDetails.releaseManagement.askForRelease.askModal.button.eventPosition'
          ),
        });
        this.props.showNotification({
          type: 'success',
          notification: 'releasePDFUploadSuccess',
          customCopy: t(
            'pview.marketDetails.releaseManagement.askForRelease.uploadModal.success.headline'
          ),
        });
      })
      .catch(() => this.setState({ submitPending: false }));
  };

  close = () => this.props.toggleUploadPDFState();

  render() {
    return (
      <Box mx={24} mb={24} mt={3}>
        <Box mb={3}>
          <ReleaseInfo
            photo={this.props.photo}
            release={this.props.release}
            position={this.props.position}
            showSelectionStrip={this.props.isMultiRelease}
            toggleViewPhotosState={this.props.toggleViewPhotosState}
          />
        </Box>
        <Form
          isMultiRelease={this.props.isMultiRelease}
          firstName={this.props.release.firstName}
          lastName={this.props.release.lastName}
          release={this.props.release}
          onSubmit={this.onSubmit}
          submitPending={this.state.submitPending}
          toggleUploadPDFState={this.props.toggleUploadPDFState}
        />
      </Box>
    );
  }
}

export default withApollo(UploadPDF);
