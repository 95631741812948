/* @flow */
import t from 'counterpart';
import get from 'lodash/get';
import { Icon } from 'eyeem-components';

import T from '../../general/translate.jsx';
import ReleaseList from '../../general/releaseList/';

import { TEST_MODAL_CLOSE } from '../../../constants/pageObjectSelectors';

import {
  RELEASE_TYPE_PROPERTY,
  RELEASE_TYPE_MODEL,
} from '../../../constants/misc';

import { isOneOf } from '../../../helpers/tools';

import AddReleaseBtn from './addReleaseBtn.jsx';

type Props = {
  className: string,
  close: CloseModal,
  photo: EyeEmPhoto,
  addRelease: Function,
};

const EditReleasesModal = (props: Props) => {
  const disableReleaseButton = (type: string) => {
    const releases = get(props, `photo.releases.${type}`);
    return !!(releases && isOneOf('pending', releases));
  };

  return props.photo ? (
    <div className={props.className}>
      <div className="modal_content">
        <button
          data-test-id={TEST_MODAL_CLOSE}
          className="dont-be-a-button modal_close"
          onClick={props.close}>
          <Icon size={18} type="close" color="greys.grey4" />
        </button>
        <T component="h2" className="modal_h2 typo-topMarginBoost-small">
          modal.editReleases.headline
        </T>
        <ReleaseList
          animate
          showRemoveBtn
          eventPosition={t('modal.editReleases.eventPosition')}
          photoId={props.photo.id}
        />
        <AddReleaseBtn
          disabled={disableReleaseButton('model')}
          addRelease={props.addRelease}
          photoId={props.photo.id}
          eventPosition={t('modal.editReleases.eventPosition')}
          releaseType={RELEASE_TYPE_MODEL}
        />
        <AddReleaseBtn
          disabled={disableReleaseButton('property')}
          addRelease={props.addRelease}
          photoId={props.photo.id}
          eventPosition={t('modal.editReleases.eventPosition')}
          releaseType={RELEASE_TYPE_PROPERTY}
        />
      </div>
      <div className="modal_buttonBox modal_buttonBox-seamless">
        <T onClick={props.close} className="g_btn g_btn-aqua g_btn-fullWidth">
          modal.editReleases.done.label
        </T>
      </div>
    </div>
  ) : null;
};

export default EditReleasesModal;
