/* @flow */

import { Component } from 'react';
import { Button } from '@eyeem-ui/atoms';
import t from 'counterpart';
import { track } from '../../helpers/tools';

type State = {
  copied: boolean,
};

type Props = {
  valueToCopy: string,
  callback?: Function,
  disabled?: boolean,
  tracking?: {
    eventName?: string,
    eventPosition?: string,
    eventLabel?: string,
    eventOption?: Object,
    eventAction?: string,
    eventType?: string,
  },
  className?: string,
  buttonCopy: {
    copy: string,
    copied: string,
  },
};

class CopyButton extends Component<Props, State> {
  textarea: HTMLTextAreaElement;

  state = {
    copied: false,
  };

  copy = () => {
    if (this.props.tracking) {
      track({
        eventType: t('tracking.eventType.inbound'),
        eventName: this.props.tracking.eventName,
        eventAction: t('tracking.eventAction.button'),
        eventLabel: this.props.tracking.eventLabel,
        eventPosition: this.props.tracking.eventPosition,
        eventOption: this.props.tracking.eventOption,
      });
    }
    if (this.props.valueToCopy) {
      navigator.clipboard.writeText(this.props.valueToCopy).then(
        () => {
          this.setState({ copied: true });
          setTimeout(() => this.setState({ copied: false }), 2500);
        },
        () => {
          console.log("copy didn't work, browser not supported?");
        }
      );
    }

    if (this.props.callback) {
      this.props.callback();
    }
  };

  render() {
    return (
      <Button
        fullWidth
        disabled={this.props.disabled}
        className={this.props.className}
        onClick={this.copy}>
        {!this.state.copied
          ? this.props.buttonCopy.copy
          : this.props.buttonCopy.copied}
      </Button>
    );
  }
}

export default CopyButton;
