import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import RemoveReleaseWarning from './removeReleaseWarning.jsx';

import { openModal } from '../../../helpers/actionCreators/redux';

import { getAuthUserNickname } from '../../../reducers/';
import { REMOVE_RELEASE } from '../../../graphql/mutations/release';
import { GET_PHOTO_WITH_RELEASE_LIST } from '../../../graphql/queries/photo';

const mapStateToProps = (state) => ({
  authUserNickname: getAuthUserNickname(state),
});

export default compose(
  graphql(GET_PHOTO_WITH_RELEASE_LIST, {
    props: ({ data: { photo }, ownProps }) => {
      const release = [
        ...(photo?.releases?.model || []),
        ...(photo?.releases?.property || []),
      ].find((item) => item.id === ownProps.releaseId);
      return { release, ...ownProps };
    },
    options: (ownProps) => ({
      variables: {
        photoId: ownProps.photoId,
        isPhotographer: true,
      },
    }),
  }),
  graphql(REMOVE_RELEASE, {
    name: 'removeRelease',
    options: (ownProps) => ({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_PHOTO_WITH_RELEASE_LIST,
          variables: {
            photoId: ownProps.photoId,
            isPhotographer: true,
          },
        },
      ],
    }),
  }),
  connect(mapStateToProps, {
    openModal,
  })
)(RemoveReleaseWarning);
