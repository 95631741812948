/* @flow */
/* eslint-env browser */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment } from 'react';
import t from 'counterpart';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { Button, Text, Flex, Box, Spinner } from '@eyeem-ui/atoms';
import { jsx } from 'theme-ui'; // eslint-disable-line

import {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_OTHER,
  RELEASE_TYPE_MODEL,
} from '../../../../constants/misc';

import { RadioGroup, FileUpload, BirthdaySelect } from '../../../formElements/';

import validate, {
  VALIDATOR_REQUIRED,
  VALIDATOR_DATE,
  composeValidators,
} from '../../../../helpers/validators';
import { track } from '../../../../helpers/tools';

type Props = {
  release: EyeEmRelease | EyeEmMultiRelease,
  contentClassName: string,
  submitPending: boolean,
  onSubmit: Function,
  isMultiRelease: boolean,
  toggleUploadPDFState: Function,
} & FormRenderProps;

const required = validate(VALIDATOR_REQUIRED);
const isDate = validate(VALIDATOR_DATE);
const translatePrefix =
  'pview.marketDetails.releaseManagement.askForRelease.uploadModal';

const translateEnding = (isMultiRelease: boolean) =>
  isMultiRelease ? 'multi' : 'single';

function UploadReleasePDFForm(props: Props) {
  const radioOptions = [
    {
      value: GENDER_MALE,
      text: t(`${translatePrefix}.genderRadio.${GENDER_MALE.toLowerCase()}`),
    },
    {
      value: GENDER_FEMALE,
      text: t(`${translatePrefix}.genderRadio.${GENDER_FEMALE.toLowerCase()}`),
    },
    {
      value: GENDER_OTHER,
      text: t(`${translatePrefix}.genderRadio.${GENDER_OTHER.toLowerCase()}`),
    },
  ];

  const onSubmit = (values) => {
    props.onSubmit(values);
  };

  const getPhotoIds = () =>
    props.release?.photoIds
      ? props.release?.photoIds[0]
      : props.release.photos.map((photo) => photo.id);

  const getTracking = () => {
    track({
      eventType: 'click_inbound',
      eventAction: 'click_button',
      eventLabel: getPhotoIds(),
      eventName: `release_${props.release.releaseType.toLowerCase()}_upload`,
      eventPosition: t(
        'pview.marketDetails.releaseManagement.askForRelease.askModal.button.eventPosition'
      ),
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={(formprops) => {
        return (
          <form onSubmit={formprops.handleSubmit}>
            {props.release.releaseType === RELEASE_TYPE_MODEL ? (
              <Fragment>
                <Box mt={1}>
                  <Text variant="title6" color="ghost70" bold>
                    {t(
                      `${translatePrefix}.birthdaySelect.label.${translateEnding(
                        props.isMultiRelease
                      )}`,
                      {
                        firstName: props.firstName,
                        lastName: props.lastName,
                      }
                    )}
                  </Text>
                </Box>
                <Box mb={3}>
                  <Field
                    component={BirthdaySelect}
                    name="birthdate"
                    validate={composeValidators(isDate)}
                  />
                </Box>
                <Box mb={1}>
                  <Text variant="title6" color="ghost70" bold>
                    {t(
                      `${translatePrefix}.genderRadio.label.${translateEnding(
                        props.isMultiRelease
                      )}`,
                      {
                        firstName: props.firstName,
                        lastName: props.lastName,
                      }
                    )}
                  </Text>
                </Box>
                <Box mb={3}>
                  <Field
                    name="gender"
                    component={RadioGroup}
                    options={radioOptions}
                    validate={composeValidators(required)}
                  />
                </Box>
              </Fragment>
            ) : null}
            <Box mt={3} pb={1}>
              <Text variant="title6" color="ghost70" bold>
                {t(`${translatePrefix}.pdfInput.label`, {
                  count: props.photos && props.photos.length,
                })}
              </Text>
            </Box>
            <Box mb={3}>
              <Field name="releaseFile" validate={composeValidators(required)}>
                {(props) => (
                  <FileUpload
                    {...props}
                    input={{
                      ...props.input,
                      onChange: (event) => {
                        getTracking();
                        props.input.onChange(event);
                      },
                    }}
                    trigger={
                      <Text
                        sx={{
                          variant: 'buttons.secondary',
                          display: 'inline-block',
                        }}>
                        {t(`${translatePrefix}.pdfInput.button`)}
                      </Text>
                    }
                    clearInputTrigger={
                      <Text>{t(`${translatePrefix}.pdfInput.remove`)}</Text>
                    }
                  />
                )}
              </Field>
            </Box>
            <Flex justifyContent="flex-end" mt={2}>
              <Button
                variant="subtle"
                disabled={props.submitPending}
                spinner={props.submitPending}
                type="submit"
                onClick={props.toggleUploadPDFState}>
                {t('multipleReleases.releaseInfo.back.label')}
              </Button>
              <Box ml={2}>
                <Button disabled={props.submitPending} type="submit">
                  {props.submitPending ? (
                    <Box
                      sx={{
                        position: 'relative',
                        height: '24px',
                        minWidth: '45px',
                      }}>
                      <Spinner color="grey0" size="24" />
                    </Box>
                  ) : (
                    t('multipleReleases.releaseInfo.next.label')
                  )}
                </Button>
              </Box>
            </Flex>
          </form>
        );
      }}
    />
  );
}

export default UploadReleasePDFForm;
