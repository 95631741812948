/* @flow */
import Photo from '../photo/';

const ICON_WIDTH = 40;

const getBackgroundDimensions = (photo, release, displayWidth) => {
  const absX1 = release.tagX1 * photo.width;
  const absX2 = release.tagX2 * photo.width;
  const absY1 = release.tagY1 * photo.height;
  const absY2 = release.tagY2 * photo.height;

  const absoluteFaceBoxWidth = absX2 - absX1;
  const absoluteFaceBoxHeight = absY2 - absY1;

  if (absoluteFaceBoxWidth > absoluteFaceBoxHeight) {
    const backgroundWidth = (photo.width / absoluteFaceBoxWidth) * displayWidth;
    return {
      width: backgroundWidth,
      height: (backgroundWidth / photo.width) * photo.height,
    };
  }

  // it's a square, so don't worry about the use of displayWidth here
  const backgroundHeight =
    (photo.height / absoluteFaceBoxHeight) * displayWidth;
  return {
    width: (backgroundHeight / photo.height) * photo.width,
    height: backgroundHeight,
  };
};

const getFaceBoxPosition = (photo, release, backgroundDimensions) => ({
  xPos: release.tagX1 * backgroundDimensions.width,
  yPos: release.tagY1 * backgroundDimensions.height,
});

const getIconFileName = (release: EyeEmRelease | EyeEmMultiRelease) => {
  if (release.multiReleaseId || release.isMultiRelease) {
    return 'multiRelease.svg';
  }
  return `${release.releaseType.toLowerCase()}.svg`;
};

function ReleaseIcon(props: { release: EyeEmRelease, photo: EyeEmPhoto }) {
  if (props.release.tagX1 && props.photo) {
    const backgroundDimensions = getBackgroundDimensions(
      props.photo,
      props.release,
      ICON_WIDTH
    );

    const faceBoxPosition = getFaceBoxPosition(
      props.photo,
      props.release,
      backgroundDimensions
    );

    return (
      <Photo
        background
        size1="w"
        size2={props.photo.width}
        url={props.photo.previewUrl}
        backgroundPosition={`-${faceBoxPosition.xPos}px -${faceBoxPosition.yPos}px`}
        backgroundSize={`${backgroundDimensions.width}px auto`}
        className="g_media-left releaseManagement_releaseList_item_icon"
      />
    );
  }
  return (
    <Photo
      size1="sq"
      size2={ICON_WIDTH}
      className="g_media-left releaseManagement_releaseList_item_icon"
      url={`/node-static/img/releaseManagement/${getIconFileName(
        props.release
      )}`}
    />
  );
}

export default ReleaseIcon;
