/* @flow */
import { Component } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import t from 'counterpart';
import qs from 'qs';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { ThemeProvider } from '@eyeem-ui/theme';

import ReleaseIcon from './releaseIcon.jsx';
import T from '../translate.jsx';

import {
  isReleaseCleared,
  isReleaseRequested,
  isOneOf,
  track,
} from '../../../helpers/tools';

import AskForRelease from './askForRelease.jsx';
import RemoveRelease from './removeRelease.jsx';
import RemoveReleaseWarning from '../../modals/removeReleaseWarning';

import AskForReleaseModal from '../../modals/askForReleaseModal';
import { TEST_PHOTOVIEW_RELEASE_CONTAINER } from '../../../constants/pageObjectSelectors';

import {
  RELEASE_TYPE_MODEL,
  RELEASE_STATUS_CLEARED,
} from '../../../constants/misc';

const fadeInOut = keyframes`
  from {
    opacity: 0.07;
  }
  50% {
    opacity: 0.03;
  }
  to {
    opacity: 0.07;
  }
`;

const StyledReleaseListItemInner = styled.div`
  overflow: hidden;
  padding: 12px 0;
  margin: 0 30px;
  height: 65px;
  border-bottom: ${(props) =>
    props.noBorder ? 'none' : `1px solid ${props.theme.colors.grey20}`};
`;

const StyledImg = styled.img`
  animation: ${fadeInOut} 2s infinite;
`;

const ACTION_VIEW = 'ACTION_VIEW';
const ACTION_REMIND = 'ACTION_REMIND';
const ACTION_ASK = 'ACTION_ASK';
const ACTION_REMOVE = 'ACTION_REMOVE';
const ACTION_UPLOAD = 'ACTION_UPLOAD';

const NO_ACTION = 'NO_ACTION';

const getReleaseName = (release, position) => {
  if (get(release, 'ownerFirstName')) {
    return `${release.ownerFirstName} ${release.ownerLastName}`;
  }

  if (get(release, 'firstName')) {
    return `${release.firstName} ${release.lastName}`;
  }

  return t(
    `pview.marketDetails.releaseManagement.releaseNamePlaceholder.${release.releaseType}`,
    { number: parseInt(position, 10) + 1 }
  );
};

type Props = {
  openModal: OpenModal,

  release: EyeEmModelRelease | EyeEmPropertyRelease,
  position: number,

  noBorder: boolean,
  showRemoveBtn: boolean,
  showAskBtn: boolean,
  showUploadBtn: boolean,
  standalone: boolean,
  noHover: boolean,

  photoId: EyeEmAssetId,
  photo: EyeEmPhoto,

  removeRelease: Function,
  updateRelease: Function,

  authUserNickname: string,

  eventPosition: string,

  isLoginViaParam: boolean,

  itemClassName: string,
  className: string,
};

export default class ReleaseListItem extends Component<Props> {
  onClick = () => {
    if (isOneOf(this.action(), [ACTION_ASK, ACTION_REMIND, ACTION_UPLOAD])) {
      return this.askForRelease();
    }
    if (this.action() === ACTION_REMOVE) {
      return this.removeRelease();
    }
    return false;
  };

  askForRelease = () => {
    const translateString = isReleaseRequested(this.props.release)
      ? 'pending'
      : 'needed';
    track({
      eventType: 'click_inbound',
      eventName: 'access_modal_requestRelease',
      eventAction: t('tracking.eventAction.link'),
      eventLabel: t(
        `pview.marketDetails.releaseManagement.askForRelease.${translateString}`
      ),
      eventPosition: 'pv_stage_sidebar',
    });
    return this.props.openModal({
      content: AskForReleaseModal,
      close: true,
      contentProps: {
        isMultiRelease: !!this.props.release.multiReleaseId,
        releaseId: this.props.release.multiReleaseId || this.props.release.id,
        position: this.props.position,
        photo: this.props.photo,
        title:
          this.props.release.releaseType === RELEASE_TYPE_MODEL
            ? t(
                `pview.marketDetails.releaseManagement.askForRelease.uploadModal.title.modelRelease`
              )
            : t(
                `pview.marketDetails.releaseManagement.askForRelease.uploadModal.title.propertyRelease`
              ),
      },
      useEUModal: true,
    });
  };

  removeRelease = () => {
    if (this.props.release.multiReleaseId) {
      return this.props.openModal({
        content: RemoveReleaseWarning,
        close: true,
        useEUModal: true,
        contentProps: {
          title: t('multipleReleases.removeReleaseWarning.headline'),
          photoId: this.props.photoId,
          releaseId: this.props.release.id,
        },
      });
    }

    return this.props.removeRelease({
      variables: {
        payload: {
          releaseType: this.props.release.releaseType,
          releaseId: this.props.release.id,
          multiReleaseId: this.props.release.multiReleaseId,
          photoId: this.props.photoId,
          userNickname: this.props.authUserNickname,
          lastModelRelease:
            this.props.release.releaseType === RELEASE_TYPE_MODEL &&
            get(this.props.photo, 'market.modelAmount') === 1,
        },
      },
    });
  };

  action = () => {
    if (isReleaseCleared(this.props.release)) {
      return ACTION_VIEW;
    }
    if (this.props.showAskBtn) {
      if (isReleaseRequested(this.props.release)) {
        return ACTION_REMIND;
      }
      return ACTION_ASK;
    }
    if (this.props.showRemoveBtn) {
      return ACTION_REMOVE;
    }
    if (this.props.showUploadBtn) {
      return ACTION_UPLOAD;
    }
    return NO_ACTION;
  };

  eventName = () => ({
    [ACTION_VIEW]: 'release_view',
    [ACTION_REMIND]: 'release_request_remind',
    [ACTION_ASK]: 'release_request_ask',
    [ACTION_REMOVE]: `release_requirement_remove${get(
      this.props.release,
      'releaseType'
    )}`,
    [ACTION_UPLOAD]: 'release_request_upload',
    [NO_ACTION]: 'no_action',
  });

  clearedButNoReleaseFileYet = () =>
    this.props.release.status === RELEASE_STATUS_CLEARED &&
    this.props.release &&
    !this.props.release.releaseUrl;

  render() {
    const action = this.action();

    let href;

    if (
      this.props.noHover ||
      (!this.props.standalone && action === ACTION_VIEW)
    ) {
      href = this.props.release.releaseUrl;
    }

    if (
      this.props.isLoginViaParam &&
      isOneOf(action, [ACTION_ASK, ACTION_REMIND, ACTION_UPLOAD])
    ) {
      const iosLinkPath = 'eyeem://open/sharePhotoRelease';
      const params = {
        releaseType: get(this.props.release, 'releaseType'),
        releaseId: get(this.props.release, 'id'),
        photoId: this.props.photoId,
        url: get(this.props.release, 'link'),
      };
      href = `${iosLinkPath}${qs.stringify(params, { addQueryPrefix: true })}`;
    }

    return (
      <ThemeProvider>
        <T
          noTranslate
          component={href ? 'a' : 'div'}
          onClick={!href && this.onClick}
          href={href}
          target="_blank"
          eventType={t('tracking.eventType.inbound')}
          eventName={this.eventName()[action]}
          eventLabel={this.props.photoId}
          eventAction={t('tracking.eventAction.link')}
          eventPosition={this.props.eventPosition}
          className={classnames(
            'releaseManagement_releaseList_item',
            this.props.itemClassName,
            this.props.className,
            {
              'releaseManagement_releaseList_item-standalone':
                this.props.standalone,
              'releaseManagement_releaseList_item-disabled':
                this.props.noHover || this.clearedButNoReleaseFileYet(),
            }
          )}>
          <StyledReleaseListItemInner
            noBorder={this.props.noBorder}
            data-test-id={TEST_PHOTOVIEW_RELEASE_CONTAINER}>
            {this.props.release && (
              <div className="g_media">
                <div className="g_media-right">
                  {action === ACTION_VIEW && (
                    <img
                      alt={t(
                        'pview.marketDetails.releaseManagement.releaseStatus.CLEARED'
                      )}
                      className="releaseManagement_releaseList_item_checkmark"
                      src="/node-static/img/check-grey.svg"
                    />
                  )}
                  {isOneOf(action, [
                    ACTION_ASK,
                    ACTION_REMIND,
                    ACTION_UPLOAD,
                  ]) && <AskForRelease release={this.props.release} />}
                  {action === ACTION_REMOVE && <RemoveRelease />}
                </div>
                <div className="photo g_media-left releaseManagement_releaseList_item_icon">
                  <ReleaseIcon
                    photo={this.props.photo}
                    release={this.props.release}
                  />
                </div>
                <div className="g_media-body text-left">
                  <p
                    className={classnames('typo_pMin typo-noMargin', {
                      'typo-color-grey3': !this.props.standalone,
                    })}>
                    {getReleaseName(this.props.release, this.props.position)}
                  </p>
                  <T className="typo_pMin typo-color-grey3">
                    {this.clearedButNoReleaseFileYet()
                      ? 'pview.marketDetails.releaseManagement.releaseStatus.generatingFile'
                      : `pview.marketDetails.releaseManagement.releaseStatus.${this.props.release.status}`}
                  </T>
                </div>
              </div>
            )}
            {!this.props.release && (
              <StyledImg
                alt="loading"
                src="/node-static/img/releaseManagement/release-dummy.svg"
              />
            )}
          </StyledReleaseListItemInner>
        </T>
      </ThemeProvider>
    );
  }
}
