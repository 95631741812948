/* @flow */
import { Component } from 'react';
import classnames from 'classnames';
import t from 'counterpart';

import Photo from '../../general/photo/';
import T from '../../general/translate.jsx';

import {
  RELEASE_TYPE_PROPERTY,
  RELEASE_TYPE_MODEL,
} from '../../../constants/misc';

type State = {
  pending: boolean,
};

type Props = {
  releaseType: RELEASE_TYPE_PROPERTY | RELEASE_TYPE_MODEL,
  photoId: EyeEmAssetId,
  addRelease: Function,
  eventPosition: string,
};

class AddReleaseBtn extends Component<Props, State> {
  state = {
    pending: false,
  };

  settlePendingState = () => {
    this.setState({
      pending: false,
    });
  };

  addRelease = () => {
    if (!this.state.pending) {
      this.setState({
        pending: true,
      });
      this.props
        .addRelease({
          variables: {
            payload: {
              releaseType: this.props.releaseType,
              photoId: this.props.photoId,
            },
          },
        })
        .then(() => this.settlePendingState());
    }
  };

  render() {
    return (
      <T
        noTranslate
        onClick={this.addRelease}
        eventType={t('tracking.eventType.inbound')}
        eventName={`release_requirement_add${this.props.releaseType}`}
        eventLabel={this.props.photoId}
        eventAction={t('tracking.eventAction.link')}
        eventPosition={this.props.eventPosition}
        className={classnames('releaseManagement_releaseList_item', {
          'releaseManagement_releaseList_item-disabled': this.state.pending,
        })}>
        <div
          className={classnames('releaseManagement_releaseList_item_inner', {
            'releaseManagement_releaseList_item_inner-noBorder':
              this.props.releaseType === RELEASE_TYPE_PROPERTY,
          })}>
          <div className="g_media">
            <Photo
              size1="sq"
              size2={40}
              className={classnames(
                'g_media-left releaseManagement_releaseList_item_icon',
                {
                  'releaseManagement_releaseList_item_icon-pending':
                    this.state.pending,
                }
              )}
              url="/node-static/img/releaseManagement/add.svg"
            />
            <div className="g_media-body text-left">
              <T
                component="p"
                className="typo_pMin typo-noMargin typo-topMarginBoost-small2">
                modal.editReleases.addRelease.
                {this.props.releaseType}.
                {this.state.pending ? 'pending' : 'add'}
              </T>
            </div>
          </div>
        </div>
      </T>
    );
  }
}

export default AddReleaseBtn;
