import classnames from 'classnames';

import T from '../translate.jsx';

function RemoveRelease() {
  return (
    <T
      className={classnames(
        'typo_pStatic releaseManagement_releaseList_item_ask',
        'g_link g_link-red'
      )}>
      pview.marketDetails.releaseManagement.removeRelease
    </T>
  );
}

export default RemoveRelease;
