/* @flow */
/* eslint-env browser */
import { Component } from 'react';
import { Text, Box } from '@eyeem-ui/atoms';

import T from '../../general/translate.jsx';
import SelectGrid from '../../general/selectGrid/';

type Props = {
  release: EyeEmRelease | EyeEmMultiRelease,
  toggleViewPhotosState: Function,
};

class View extends Component<Props> {
  openNewTab = (params: { id: EyeEmAssetId }) =>
    window.open(`//${window.location.hostname}/p/${params.id}`, '_blank');

  render() {
    return (
      <Box>
        <Box pt={4} px={4}>
          <T
            component={Text}
            count={this.props.release.photos.length}
            with={{
              modelName: `${this.props.release.firstName} ${this.props.release.lastName}`,
              boldCount: <Text bold>{this.props.release.photos.length}</Text>,
            }}
            className="typo_pStatic typo-inline">
            multipleReleases.view.headline
          </T>
        </Box>

        <SelectGrid
          className="g_greyBackground_gradient"
          photos={this.props.release.photos}
          toggleSelection={this.openNewTab}
        />
      </Box>
    );
  }
}

export default View;
