/* @flow */

import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import ReleaseList from './releaseList.jsx';
import { GET_PHOTO_WITH_RELEASE_LIST } from '../../../graphql/queries/photo';

export default compose(
  graphql(GET_PHOTO_WITH_RELEASE_LIST, {
    props: ({ data: { photo }, ownProps }) => ({ photo, ...ownProps }),
    options: (ownProps) => ({
      variables: {
        photoId: ownProps.photoId,
        isPhotographer: true,
      },
    }),
  })
)(ReleaseList);
