/* @flow */
/* eslint-env browser */
import { Component } from 'react';
import t from 'counterpart';
import { Button, Text, Textarea, Flex, Box } from '@eyeem-ui/atoms';

import CopyButton from '../../general/copyButton.jsx';
import ReleaseInfo from './releaseInfo.jsx';
import Model from './content/model.jsx';

import { getReleaseLink, track } from '../../../helpers/tools';
import {
  RELEASE_STATUS_REQUESTED,
  RELEASE_STATUS_NEEDED,
} from '../../../constants/misc';

type Props = {
  updateRelease: Function,
  release: EyeEmModelRelease | EyeEmPropertyRelease,
  photo: EyeemAsset,
  position: number,
  fromMultiReleaseCreate: boolean,
  toggleUploadPDFState: Function,
  toggleViewPhotosState: Function,
  isMultiRelease: boolean,
};

type State = {
  viewPhotos: boolean,
  viewPDFUpload: boolean,
};

class AskForModelReleaseContent extends Component<Props, State> {
  textarea: HTMLTextAreaElement;

  getPhotoIds = () =>
    this.props.release?.photoIds ||
    this.props.release.photos.map((photo) => photo.id);

  getIsMultiRelease = () =>
    this.props.isMultiRelease ||
    this.props.release.isMultiRelease ||
    this.props.release.multiReleaseId;

  markReleaseRequested = () => {
    if (this.props.release.status === RELEASE_STATUS_NEEDED) {
      this.props.updateRelease({
        variables: {
          payload: {
            isMultiRelease: this.getIsMultiRelease(),
            id: this.props.release.multiReleaseId || this.props.release.id,
            status: RELEASE_STATUS_REQUESTED,
          },
        },
      });
    }
  };

  getTracking = () => {
    if (this.props.fromMultiReleaseCreate) {
      return {
        eventName: t('multipleReleases.askModel.button.eventName'),
        eventPosition: t('multipleReleases.askModel.eventPosition'),
        eventLabel: t('multipleReleases.askModel.button.copy'),
        eventOption: {
          photosSelected: this.getPhotoIds().length,
          type: 'model',
        },
      };
    }
    return {
      eventName: t(
        'pview.marketDetails.releaseManagement.askForRelease.askModal.button.eventName'
      ),
      eventPosition: t(
        'pview.marketDetails.releaseManagement.askForRelease.askModal.button.eventPosition'
      ),
      eventLabel: this.props.photo.id,
    };
  };

  toggleUploadModal = () => {
    track({
      eventType: 'click_inbound',
      eventAction: 'click_button',
      eventLabel: t(
        'pview.marketDetails.releaseManagement.askForRelease.askModal.pdfUpload.link'
      ),
      eventName: 'access_modal_uploadModelRelease',
      eventPosition: t(
        'pview.marketDetails.releaseManagement.askForRelease.askModal.button.eventPosition'
      ),
    });
    this.props.toggleUploadPDFState();
  };

  render() {
    return (
      <Box>
        <Box px={24} pb={24}>
          <ReleaseInfo
            photo={this.props.photo}
            release={this.props.release}
            position={this.props.position}
            showSelectionStrip={this.getIsMultiRelease()}
            toggleViewPhotosState={this.props.toggleViewPhotosState}
          />
          <Model count={this.getPhotoIds().length} />
          <Box pt={3}>
            <Textarea
              readOnly
              rows="2"
              value={getReleaseLink(this.props.release)}
            />
          </Box>
          <Box pt={2}>
            <Text color="grey50">
              {t(
                'pview.marketDetails.releaseManagement.askForRelease.askModal.subline.model.upload'
              )}
            </Text>
          </Box>
          <Flex justifyContent="space-between" mt={3}>
            <Box>
              <Button variant="secondary" onClick={this.toggleUploadModal}>
                {t(
                  'pview.marketDetails.releaseManagement.askForRelease.askModal.pdfUpload.link'
                )}
              </Button>
            </Box>
            <Box>
              <CopyButton
                valueToCopy={getReleaseLink(this.props.release)}
                callback={this.markReleaseRequested}
                buttonCopy={{
                  copy: t(
                    'pview.marketDetails.releaseManagement.askForRelease.askModal.button.copy'
                  ),
                  copied: t(
                    'pview.marketDetails.releaseManagement.askForRelease.askModal.button.copied'
                  ),
                }}
                tracking={this.getTracking()}
              />
            </Box>
          </Flex>
        </Box>
      </Box>
    );
  }
}

export default AskForModelReleaseContent;
