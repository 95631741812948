/* @flow */
/* eslint-env browser */
import { Component } from 'react';
import t from 'counterpart';
import styled from '@emotion/styled';
import { Global } from '@emotion/react';
import { styleUtils } from 'eyeem-components';
import { Button, Flex } from '@eyeem-ui/atoms';

import ViewPhotos from './viewPhotos.jsx';
import UploadPDF from './uploadPDF/';
import AskForModelReleaseContent from './askForModelReleaseContent.jsx';
import AskForPropertyReleaseContent from './askForPropertyReleaseContent.jsx';
import {
  RELEASE_TYPE_MODEL,
  RELEASE_TYPE_PROPERTY,
} from '../../../constants/misc.js';

const StyledReleaseModal = styled.div`
  ${(props) =>
    props.fullScreen &&
    `
      max-width: inherit;
      height: calc(100% - 64px);
  `}
`;

const ScrollWrapper = styled.div`
  ${styleUtils.fromPropsTernary(
    'isMultiRelease',
    'overflow-y: scroll; height: calc(100% - 64px);',
    ''
  )};
`;

type Props = {
  close: CloseModal,
  className?: string,
  updateRelease: Function,
  release: EyeEmRelease | EyeEmMultiRelease,
  photo: EyeemAsset,
  position: number,
  fromMultiReleaseCreate: boolean,
  isMultiRelease: boolean,
};

type State = {
  viewPhotos: boolean,
  viewPDFUpload: boolean,
};

class AskForReleaseModal extends Component<Props, State> {
  state = {
    viewPhotos: false,
    viewPDFUpload: false,
  };

  getRelease = () => this.props.data?.release || this.props.data?.multiRelease;

  toggleViewPhotosState = () =>
    this.setState({
      viewPhotos: !this.state.viewPhotos,
    });

  toggleUploadPDFState = () =>
    this.setState({
      viewPDFUpload: !this.state.viewPDFUpload,
    });

  shouldShowViewPhotos = () =>
    this.getRelease().photos && this.state.viewPhotos;

  shouldShowPDFUpload = () =>
    !this.shouldShowViewPhotos() &&
    this.getRelease().photos &&
    this.state.viewPDFUpload;

  shouldShowAskForReleaseContent = () =>
    !this.shouldShowViewPhotos() &&
    this.getRelease().photos &&
    !this.state.viewPhotos &&
    !this.state.viewPDFUpload;

  render() {
    const release = this.getRelease();
    return release ? (
      <StyledReleaseModal
        className={this.props.className}
        fullScreen={this.state.viewPhotos}>
        <Global
          styles={
            this.state.viewPhotos
              ? {
                  '[data-reach-dialog-content]': {
                    width: `calc(100% - 64px) !important`,
                    maxHeight: `calc(100% - 64px)`,
                    overflow: 'auto',
                  },
                }
              : {}
          }
        />

        <ScrollWrapper isMultiRelease={this.props.isMultiRelease}>
          {this.shouldShowViewPhotos() && (
            <ViewPhotos
              toggleViewPhotosState={this.toggleViewPhotosState}
              release={release}
            />
          )}
          {this.shouldShowPDFUpload() && (
            <UploadPDF
              close={this.props.close}
              isMultiRelease={this.props.isMultiRelease}
              toggleUploadPDFState={this.toggleUploadPDFState}
              toggleViewPhotosState={this.toggleViewPhotosState}
              position={this.props.position}
              release={release}
              photo={this.props.photo}
            />
          )}
          {this.shouldShowAskForReleaseContent() &&
            release.releaseType === RELEASE_TYPE_MODEL && (
              <AskForModelReleaseContent
                updateRelease={this.props.updateRelease}
                photo={this.props.photo}
                release={release}
                position={this.props.position}
                isMultiRelease={this.props.isMultiRelease}
                fromMultiReleaseCreate={this.props.fromMultiReleaseCreate}
                toggleViewPhotosState={this.toggleViewPhotosState}
                toggleUploadPDFState={this.toggleUploadPDFState}
              />
            )}
          {this.shouldShowAskForReleaseContent() &&
            release.releaseType === RELEASE_TYPE_PROPERTY && (
              <AskForPropertyReleaseContent
                release={release}
                toggleUploadPDFState={this.toggleUploadPDFState}
              />
            )}
        </ScrollWrapper>
        {this.shouldShowViewPhotos() && (
          <Flex justifyContent="flex-end" p={3}>
            <Button type="submit" onClick={this.toggleViewPhotosState}>
              {t('multipleReleases.releaseInfo.back.label')}
            </Button>
          </Flex>
        )}
      </StyledReleaseModal>
    ) : null;
  }
}

export default AskForReleaseModal;
