/* @flow */
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import {
  RELEASE_TYPE_MODEL,
  RELEASE_TYPE_PROPERTY,
} from '../../../constants/misc';

import ReleaseListItem from '../releaseListItem/';

const Component = (props) =>
  props.animate ? (
    <CSSTransition
      {...props}
      classNames="releaseListItemTransition"
      timeout={{ enter: 300, exit: 300 }}
    />
  ) : (
    <div {...props} />
  );

function ReleaseList(props: {
  photo: {
    releases: {
      model: $ReadOnlyArray<EyeEmRelease | EyeEmMultiRelease>,
      property: $ReadOnlyArray<EyeEmRelease | EyeEmMultiRelease>,
    },
  } & EyeEmPhoto,
  modelReleases: $ReadOnlyArray<EyeEmRelease | EyeEmMultiRelease>,
  // eslint-disable-next-line react/no-unused-prop-types
  showRemoveBtn: boolean, // used inside a .map()
  // eslint-disable-next-line react/no-unused-prop-types
  showAskBtn: boolean, // used inside a .map()
  // eslint-disable-next-line react/no-unused-prop-types
  eventPosition: string, // used inside a .map()
  animate: boolean,
  itemClassName: string,
}) {
  if (!props.photo?.releases) {
    return <></>;
  }

  const { model = [], property: propertyReleases = [] } = props.photo.releases;
  const modelReleases =
    model && model.length > 0
      ? [...model].sort((releaseA, releaseB) => {
          // if it doesn't contain a multireleaseid put it down the list
          if (typeof releaseA.multiReleaseId === 'undefined') return 1;
          if (typeof releaseB.multiReleaseId === 'undefined') return 0;
          return 0;
        })
      : [];

  const Wrapper = props.animate ? TransitionGroup : 'div';

  return (
    <div className="releaseManagement_releaseList">
      <Wrapper>
        {modelReleases?.map((release, i) => (
          <Component key={release.id} animate={props.animate}>
            <ReleaseListItem
              itemClassName={props.itemClassName}
              eventPosition={props.eventPosition}
              showRemoveBtn={props.showRemoveBtn}
              showAskBtn={props.showAskBtn}
              releaseType={RELEASE_TYPE_MODEL}
              photo={props.photo}
              position={i}
              release={release}
            />
          </Component>
        ))}
        {propertyReleases?.map((release, i) => (
          <Component key={release.id} animate={props.animate}>
            <ReleaseListItem
              itemClassName={props.itemClassName}
              eventPosition={props.eventPosition}
              showRemoveBtn={props.showRemoveBtn}
              showAskBtn={props.showAskBtn}
              key={release.id}
              type={RELEASE_TYPE_PROPERTY}
              photo={props.photo}
              position={i}
              release={release}
            />
          </Component>
        ))}
      </Wrapper>
    </div>
  );
}

export default ReleaseList;
