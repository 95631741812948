/* @flow */
/* eslint-env browser */
import { Component } from 'react';
import t from 'counterpart';
import { Button, Flex, Box } from '@eyeem-ui/atoms';

import PropertyContent from './content/property';
import { track } from '../../../helpers/tools';

type Props = {
  release: EyeEmModelRelease | EyeEmPropertyRelease,
  toggleUploadPDFState: Function,
};

type State = {
  viewPhotos: boolean,
  viewPDFUpload: boolean,
};

class AskForReleaseContent extends Component<Props, State> {
  textarea: HTMLTextAreaElement;

  toggleUploadModal = () => {
    track({
      eventType: 'click_inbound',
      eventAction: 'click_button',
      eventLabel: t(
        'pview.marketDetails.releaseManagement.askForRelease.askModal.pdfUpload.link'
      ),
      eventName: 'access_modal_uploadPropertyRelease',
      eventPosition: t(
        'pview.marketDetails.releaseManagement.askForRelease.askModal.button.eventPosition'
      ),
    });
    this.props.toggleUploadPDFState();
  };

  render() {
    return (
      <Box>
        <Box px={24} pb={24} pt={3}>
          <Box mt={3}>
            <PropertyContent />
          </Box>
          <Flex justifyContent="space-between" mt={3}>
            <Box>
              <Button variant="secondary" onClick={this.toggleUploadModal}>
                {t(
                  'pview.marketDetails.releaseManagement.askForRelease.askModal.pdfUpload.link'
                )}
              </Button>
            </Box>
          </Flex>
        </Box>
      </Box>
    );
  }
}

export default AskForReleaseContent;
