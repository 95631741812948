/** @flow */
/* eslint-env browser */
import get from 'lodash/get';
import t from 'counterpart';
import { Box, Button, Flex, Text } from '@eyeem-ui/atoms';

import EditReleasesModal from '../editReleases/';
import ReleaseIcon from '../../general/releaseListItem/releaseIcon';

import { RELEASE_STATUS_CLEARED } from '../../../constants/misc';

type Props = {
  release: EyeEmRelease | EyeEmMultiRelease,
  photoId: EyeEmAssetId,
  position: number,
  removeRelease: Function,
  authUserNickname: string,
  openModal: OpenModal,
};

const RemoveReleaseWarning = (props: Props) => {
  const removeRelease = () => {
    if (props.release) {
      props.removeRelease({
        variables: {
          payload: {
            releaseType: props.release.releaseType,
            releaseId: props.release.id,
            multiReleaseId: props.release.multiReleaseId,
            photoId: props.photoId,
            userNickname: props.authUserNickname,
            lastModelRelease: get(props.asset, 'market.modelAmount') === 1,
          },
        },
      });
      return backToReleasesModal();
    }
  };

  const getReleaseName = (release, position) => {
    if (get(release, 'ownerFirstName')) {
      return `${release.ownerFirstName} ${release.ownerLastName}`;
    }

    if (get(release, 'firstName')) {
      return `${release.firstName} ${release.lastName}`;
    }

    return t(
      `pview.marketDetails.releaseManagement.releaseNamePlaceholder.${release.releaseType}`,
      { number: parseInt(position, 10) + 1 }
    );
  };

  const clearedButNoReleaseFileYet = () =>
    props.release.status === RELEASE_STATUS_CLEARED &&
    props.release &&
    !props.release.releaseUrl;

  const backToReleasesModal = () =>
    props.openModal({
      content: EditReleasesModal,
      contentProps: {
        photoId: props.photoId,
      },
    });

  return (
    <Box p="24px">
      {props.release ? (
        <Box mb="3">
          <Flex>
            <ReleaseIcon release={props.release} photo={props.asset} />
            <Box>
              <Flex flexDirection="column">
                <Box>
                  <Text bold>
                    {getReleaseName(props.release, props.position)}
                  </Text>
                </Box>
                <Box>
                  <Text variant="title6" color="grey50">
                    {t(
                      clearedButNoReleaseFileYet()
                        ? 'pview.marketDetails.releaseManagement.releaseStatus.generatingFile'
                        : `pview.marketDetails.releaseManagement.releaseStatus.${props.release.status}`
                    )}
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      ) : null}
      <Box mb="3">
        <Text color="grey50">
          {t('multipleReleases.removeReleaseWarning.subline')}
        </Text>
      </Box>
      <Flex justifyContent="flex-end">
        <Box mr="2">
          <Button variant="secondary" onClick={backToReleasesModal}>
            {t('multipleReleases.removeReleaseWarning.button.cancel')}
          </Button>
        </Box>
        <Button variant="alert" onClick={removeRelease}>
          {t('multipleReleases.removeReleaseWarning.button.remove')}
        </Button>
      </Flex>
    </Box>
  );
};

export default RemoveReleaseWarning;
