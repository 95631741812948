import { gql } from '@apollo/client';

export const GET_RELEASE = gql`
  query release($releaseId: ID!) {
    release(releaseId: $releaseId) {
      id
      isMultiRelease
      status
      releaseType
      link
      photoIds
      photos {
        id
        previewUrl: photoUrl
        user {
          id
          thumbUrl
          fullname
        }
      }
      ... on ModelRelease {
        firstName
        lastName
      }
      ... on PropertyRelease {
        ownerFirstName
        ownerLastName
      }
    }
  }
`;

export const GET_MULTI_RELEASE = gql`
  query multiRelease($releaseId: ID!) {
    multiRelease(releaseId: $releaseId) {
      id
      isMultiRelease
      status
      releaseType
      link
      photoIds
      photos {
        id
        previewUrl: photoUrl
        user {
          id
          thumbUrl
          fullname
        }
      }
      ... on ModelRelease {
        firstName
        lastName
      }
      ... on PropertyRelease {
        ownerFirstName
        ownerLastName
      }
    }
  }
`;
