import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import ReleaseListItem from './releaseListItem.jsx';

import { getAuthUserNickname, getIsLoginViaParam } from '../../../reducers/';

import { openModal } from '../../../helpers/actionCreators/redux';
import { GET_PHOTO_WITH_RELEASE_LIST } from '../../../graphql/queries/photo';
import { SINGLE_PHOTO_QUERY_ALIAS } from '../../../graphql/constants';

import {
  REMOVE_RELEASE,
  UPDATE_RELEASE,
} from '../../../graphql/mutations/release';

const mapStateToProps = (state, ownProps) => ({
  authUserNickname: getAuthUserNickname(state),
  isLoginViaParam: getIsLoginViaParam(state),
  photoId: ownProps.photo.id,
  release: ownProps.release,
});

export default compose(
  graphql(REMOVE_RELEASE, {
    name: 'removeRelease',
    options: (ownProps) => ({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_PHOTO_WITH_RELEASE_LIST,
          variables: {
            photoId: ownProps.photo.id,
            isPhotographer: true,
          },
        },
        SINGLE_PHOTO_QUERY_ALIAS,
      ],
    }),
  }),
  graphql(UPDATE_RELEASE, {
    name: 'updateRelease',
    options: (ownProps) => ({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_PHOTO_WITH_RELEASE_LIST,
          variables: {
            photoId: ownProps.photo.id,
            isPhotographer: true,
          },
        },
        SINGLE_PHOTO_QUERY_ALIAS,
      ],
    }),
  }),
  connect(mapStateToProps, {
    openModal,
  })
)(ReleaseListItem);
