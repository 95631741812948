/* @flow */
import classnames from 'classnames';

import T from '../translate.jsx';

import { isReleaseRequested } from '../../../helpers/tools';
import { RELEASE_TYPE_PROPERTY } from '../../../constants/misc';

function AskForRelease(props: { release: EyeEmRelease }) {
  const isPropertyRelease = props.release.releaseType === RELEASE_TYPE_PROPERTY;
  const wasRequested = isReleaseRequested(props.release);

  let translateString;

  if (isPropertyRelease) {
    translateString = 'upload';
  } else {
    translateString = wasRequested ? 'pending' : 'needed';
  }

  return (
    <T
      className={classnames(
        'typo_pStatic g_link releaseManagement_releaseList_item_ask',
        {
          'g_link-aqua': isPropertyRelease || !wasRequested,
          'g_link-orange': !isPropertyRelease && wasRequested,
        }
      )}>
      {`pview.marketDetails.releaseManagement.askForRelease.${translateString}`}
    </T>
  );
}

export default AskForRelease;
