/* @flow */
/* eslint-env browser */
import ReleaseListItem from '../../general/releaseListItem/';
import SelectionStrip from '../../general/selectionStrip/';

type Props = {
  photo: EyeemAsset,
  release: EyeEmRelease | EyeEmMultiRelease,
  position: number,
  showSelectionStrip: boolean,
  showReleaseListItem?: boolean,
  toggleViewPhotosState: Function,
};

function ReleaseInfo(props: Props) {
  const photoIds =
    props.release?.photoIds || props.release.photos.map((photo) => photo.id);

  return (
    <div>
      {props.showReleaseListItem && (
        <ReleaseListItem
          photo={props.photo}
          release={props.release}
          releaseType={props.release.releaseType}
          position={props.position}
          itemClassName={'releaseManagement_releaseList_item-dark'}
        />
      )}
      {props.showSelectionStrip && (
        <SelectionStrip
          buttonAction={props.toggleViewPhotosState}
          photos={props.release.photos}
          firstName={props.release.firstName}
          lastName={props.release.lastName}
          eventOption={{
            photosSelected: photoIds.length,
            type: 'model',
          }}
        />
      )}
    </div>
  );
}

export default ReleaseInfo;
