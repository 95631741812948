/** @jsxRuntime classic */
/** @jsx jsx */
/* @flow */
/* eslint-env browser */
import { Fragment } from 'react';
import t from 'counterpart';
import { jsx } from 'theme-ui'; // eslint-disable-line
import { Button, Text, Box } from '@eyeem-ui/atoms';

import T from '../../../general/translate';

const Property = () => {
  const bullets = ['first', 'second', 'third'];

  return (
    <Fragment>
      <Text color="grey50">
        {t(
          'pview.marketDetails.releaseManagement.askForRelease.askModal.subline.property.intro'
        )}
      </Text>
      <Box pl={3} py={2}>
        <ul
          sx={{
            listStyleType: 'disc',
            color: 'grey50',
          }}>
          {bullets.map((bullet) => (
            <li key={bullet}>
              <Text color="grey50">
                {t(
                  `pview.marketDetails.releaseManagement.askForRelease.askModal.subline.property.${bullet}`
                )}
              </Text>
            </li>
          ))}
        </ul>
      </Box>
      <a
        target="_blank"
        rel="noreferrer"
        href={t(
          'pview.marketDetails.releaseManagement.askForRelease.askModal.subline.property.link.href'
        )}>
        <Button variant="subtle">
          {t(
            'pview.marketDetails.releaseManagement.askForRelease.askModal.subline.property.link.text'
          )}
        </Button>
      </a>
      <Box py={2}>
        <T
          component={Text}
          color="grey50"
          with={{
            link: (
              <a
                sx={{ color: 'grey100' }}
                target="_blank"
                rel="noreferrer"
                href={t(
                  'pview.marketDetails.releaseManagement.askForRelease.askModal.subline.property.download.link.href'
                )}>
                {t(
                  'pview.marketDetails.releaseManagement.askForRelease.askModal.subline.property.download.link.text'
                )}
              </a>
            ),
          }}>
          pview.marketDetails.releaseManagement.askForRelease.askModal.subline.property.download.instructions
        </T>
      </Box>
    </Fragment>
  );
};

export default Property;
