/* @flow */
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import { GET_PHOTO_WITH_RELEASE_LIST } from '../../../graphql/queries/photo';
import { SINGLE_PHOTO_QUERY_ALIAS } from '../../../graphql/constants';
import { UPDATE_RELEASE } from '../../../graphql/mutations/release';
import {
  GET_RELEASE,
  GET_MULTI_RELEASE,
} from '../../../graphql/queries/release';

import AskForReleaseModal from './askForReleaseModal.jsx';

export default compose(
  graphql(GET_RELEASE, {
    skip: (ownProps: {
      isMultiRelease: boolean,
      fromMultiReleaseCreate: boolean,
    }) => ownProps.fromMultiReleaseCreate || ownProps.isMultiRelease,
    options: (ownProps: { releaseId: EyeEmReleaseId }) => ({
      variables: { releaseId: ownProps.releaseId },
    }),
  }),
  graphql(GET_MULTI_RELEASE, {
    skip: (ownProps: {
      isMultiRelease: boolean,
      fromMultiReleaseCreate: boolean,
      releaseId: EyeEmReleaseId,
    }) =>
      !ownProps.releaseId ||
      (!ownProps.fromMultiReleaseCreate && !ownProps.isMultiRelease),
    options: (ownProps: { releaseId: EyeEmReleaseId }) => ({
      variables: { releaseId: ownProps.releaseId },
    }),
  }),
  graphql(UPDATE_RELEASE, {
    name: 'updateRelease',
    options: (ownProps) => {
      const refetchQueries = ownProps.isMultiRelease
        ? [
            SINGLE_PHOTO_QUERY_ALIAS,
            {
              query: GET_MULTI_RELEASE,
              variables: { releaseId: ownProps.releaseId },
            },
          ]
        : [
            {
              query: GET_PHOTO_WITH_RELEASE_LIST,
              variables: {
                photoId: ownProps.photo.id,
                isPhotographer: true,
              },
            },
            SINGLE_PHOTO_QUERY_ALIAS,
            {
              query: GET_RELEASE,
              variables: { releaseId: ownProps.releaseId },
            },
          ];
      return {
        awaitRefetchQueries: true,
        refetchQueries: refetchQueries,
      };
    },
  })
)(AskForReleaseModal);
